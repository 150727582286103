import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";

import { BackgroundImage } from "react-image-and-background-image-fade";

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/styles";

import { IconContext } from "@react-icons/all-files";
import { HiCode } from "@react-icons/all-files/hi/HiCode";

import { BsArrowRight } from "@react-icons/all-files/bs/BsArrowRight";
import { TiFilter } from "@react-icons/all-files/ti/TiFilter";
import { IoClose } from "@react-icons/all-files/io5/IoClose";

import styles from "../styles/portfolioSummary.module.scss";
import labelStyles from "../styles/label.module.scss";

import cleanHtml from "../utils/cleanHtml";

const CSSCheckbox = withStyles({
  root: {
    padding: "6px 8px",
  }
})(Checkbox);


const CSSFormControlLabel = withStyles({
  root: {
    '& span': {
      fontFamily: "'Source Sans Pro', Helvetica, sans-serif",
    }
  }
})(FormControlLabel);


const FILTERS = {
  Consulting: false,
  Design: false,
  Development: false,
  Freelance: false,
  "Full-time": false,
  "Non-Professional": false,
  "Part-time": false,
  Professional: false,
  University: false,
};

const FILTERS_LENGTH = Object.keys(FILTERS).length;

export default function Portfolio({ data }) {
  const projects = data.contentfulContentOrder.contentModel;
  /* Set State */
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ filters, setFilters ] = useState(FILTERS);

  /* Popover Logic */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filters-popover' : undefined;

  function getThumbnail(image, slug) {
    return image ? `${process.env.GATSBY_ASSETS_URL}/${slug}/${image}` : `${process.env.GATSBY_ASSETS_URL}/no-image.png`;
  }


  /* Filter Logic */
  const handleCheckboxChange = (event, name) => {
    const key = name ? name : event.target.name;
    const value = name ? false : event.target.checked;
    setFilters({ ...filters, [key]: value });
  };

  function countFilters() {
    return Object.values(filters).filter(item => item === true).length;
  }

  function clearFilters() {
    setFilters(FILTERS);
  }

  function filteredProjects() {
    const numFilters = countFilters();

    if (numFilters === 0 || numFilters === FILTERS_LENGTH) {
      return projects;
    } else {
      /* Handle "Complex" Filters */
      const nonProfessionalLogic = {
        inFilter: filters["Non-Professional"],
        antiKey: "Professional",
        antiKeyExists: false,
      };

      return projects.filter(project => {
        let inFilter = false;
        
        project.tags.map((tag) => {
          if (tag === nonProfessionalLogic.antiKey) {
            nonProfessionalLogic.antiKeyExists = true;
          }

          inFilter = inFilter || filters[tag];
          return tag;
        });


        if (nonProfessionalLogic.inFilter && !nonProfessionalLogic.antiKeyExists) {
          inFilter = true;
        }

        return inFilter;
      });
    };
  }


  /* Use Effect */
  useEffect(() => {

  }, [filters, anchorEl]);

  return (
    <div>
      <div className={ `header__container`}>
        <h1 className={ `header ${styles.header}` }>Portfolio</h1>
        <div className={ styles.filters__container }>
          <div>
            <Button className={ styles.filters__button }
              variant="contained"
              disableElevation
              color="primary"
              size="small"
              onClick={(e) => handleClick(e)}
              startIcon={<TiFilter />}>
              Filters { countFilters() === 0 ? null : ` | ${countFilters()}` }
            </Button>
            <Popover
              id={id}
              elevation={2}
              open={open}
              anchorEl={anchorEl}
              onClose={() => handleClose()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={styles.popover__body}>
                <FormControl component="fieldset">
                  <Button onClick={() => clearFilters()} color="primary" size="small" endIcon={<IoClose/>}>Clear All</Button>
                  <FormGroup>
                    { Object.entries(filters).map((item) => {
                      return <CSSFormControlLabel
                        color="primary"
                        key={item[0]}
                        control={<CSSCheckbox color="primary" checked={item[1]} onChange={(e) => handleCheckboxChange(e, null)} name={item[0]}/>}
                        label={item[0]}
                      />
                    })}
                  </FormGroup>
                </FormControl>
              </div>
            </Popover>
          </div>

          <div>
            { Object.entries(filters).map(item => {
                return item[1] 
                  ? 
                  <div className={ `${labelStyles.label} ${labelStyles.label__selected}` }>
                    {item[0]}
                    <IconContext.Provider value={{ size: "20px" }}>
                      <IoClose className={`${labelStyles.close__icon} icon__right`} onClick={(e) =>  handleCheckboxChange(e, item[0])} />
                    </IconContext.Provider>
                  </div>
                  : null
              })
            }
          </div>
        </div>
      </div>

      {/* PROJECTS */}
      <div className={ styles.container }>
        { filteredProjects().map(project => { return (
          <div className={ styles.project } key={ project.slug }>
            <BackgroundImage
              src={ getThumbnail(project.thumbnailImage, project.slug)} 
              alt={ `${project.title} Thumbnail`}
              wrapperClassName={`${ styles.project__img } ${ project.thumbnailTopPosition ? styles.project__img__top : '' }`}
              width="100%"
              height="320px"
              lazyLoad
            />

            <div className={ styles.project__content }>
              <h3 className={ styles.project__title }>{ project.title }</h3>
              <div className={ styles.project__subtitle } dangerouslySetInnerHTML={{ __html: cleanHtml(project.subtitle.childMarkdownRemark.html) }}></div>
              { project.tags.map(tag => <div className={ labelStyles.label } key={tag}>{tag}</div>) }
              <div className={ styles.project__tech }>
                <IconContext.Provider value={{ size: '20px', color: '#FF9A23' }}>
                  <HiCode className={ `icon__left icon__1px__up ${ styles.project__tech__icon }` } />
                </IconContext.Provider>
                <div dangerouslySetInnerHTML={{ __html: cleanHtml(project.techOverview.childMarkdownRemark.html) }}></div>
              </div>
            </div>
            {/* Absolute backdrop */}
            <Link to={`/portfolio/${project.slug}`} className={ styles.project__backdrop }>
              <div className={ styles.backdrop__text }>
                <div><b>Click</b> to learn more</div>
                <IconContext.Provider value={{ size: '20px'}}>
                  <BsArrowRight className={ `icon__right icon__1px__down` }/>
                </IconContext.Provider>
              </div>
            </Link>
          </div>
        )})}
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query {
    contentfulContentOrder(contentName: {eq: "Project"}) {
      contentModel {
        ... on ContentfulProject {
          title
          subtitle {
            childMarkdownRemark {
              html
            }
          }
          tags
          techOverview {
            childMarkdownRemark {
              html
            }
          }
          slug
          thumbnailImage
          thumbnailTopPosition
          isProfessional
        }
      }
    }
  }
`;
